
const About = () => {
  return (
    <article className="flex flex-col min-h-screen col-span-2 items-center p-5">
      <section className="flex flex-col items-center mt-10 text-2xl gap-5 text-slate-600">
        <h2 className="text-5xl text-center uppercase text-black">About</h2>
        <p className="text-base  sm:text-lg ">
         
I am deeply passionate about social work and aspire to become a surgeon. While the two may seem unrelated, my interest in social work has greatly influenced my pursuit of a career in medicine. I believe that as a surgeon, I can make a meaningful impact on the lives of individuals and communities by providing crucial medical care and improving their overall well-being. I am driven by a desire to address the inequities in healthcare and ensure that everyone, regardless of their background, has access to quality surgical interventions. My passion for social work fuels my commitment to serve others and make a positive difference in their lives through the field of surgery.<br></br><br></br>All images are copyrights of Anish Nayak.
        </p>
      </section>
          </article>
  )
}

export default About
