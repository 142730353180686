import Github from '../../assets/images/socials/github.svg'
import Insta from '../../assets/images/socials/insta.svg'

const Socials = () => {
  return (
    <section className="flex flex-row gap-3">
      <a
        href="https://github.com/Philatelistguy"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="w-6 h-6	max-w-full" src={Github} alt="Github icon" />
      </a>
      <a
        href="https://www.instagram.com/maybe.am_anish"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="w-6 h-6	max-w-full" src={Insta} alt="Instagram icon" />
      </a>
    </section>
  )
}

export default Socials
