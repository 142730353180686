import { useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Burger from '../../assets/images/burger-menu.png'
import Socials from './Socials'
import CSS from 'csstype'

const activeMenuStyles: CSS.Properties = {
  textDecoration: 'none',
  color: 'rgb(37, 99, 235)',
}

const SideMenu = () => {
  const [hiddenMenu, setHiddenMenu] = useState<boolean>(true)
  const ref = useRef<HTMLImageElement>(null)

  function ToggleMenu() {
    hiddenMenu ? setHiddenMenu(false) : setHiddenMenu(true)
  }
  function RemoveMenu() {
    setHiddenMenu(true)
    window.scrollTo(0, 0)
  }

  return (
    <div className='bg-gradient-to-r from-teal-300 w-16 md:w-96 to-green-300 '>
    <header className="sm:relative flex flex-col min-h-screen col-span-1 items-center absolute">
      
      <img
        className="burger-icon w-10 fixed cursor-pointer md:hidden z-50 "
        onClick={ToggleMenu}
        ref={ref}
        src={Burger}
        alt=""
        aria-hidden="true"
      />
      <nav
        className={`main-menu${
          hiddenMenu ? `` : ` show-menu`
        } flex-col items-center fixed mt-60 text-2xl gap-5 text-slate-600 hidden md:flex`}
      >
        <h1 className="sm:text-xl md:text-3xl text-center  text-black font-light">
          
          <span className="font-semibold font-namefont">Socially Useful and Productive Work</span>
        </h1>
        <NavLink
          className="hover:text-blue-600 smooth-transition text-xl"
          to="/"
          onClick={() => {
            RemoveMenu()
          }}
        >
          Gallery
        </NavLink>
      
        <NavLink
          className="hover:text-blue-600 smooth-transition text-xl"
          to="/about"
          onClick={() => {
            RemoveMenu()
          }}
          style={({ isActive }) => (isActive ? activeMenuStyles : {})}
        >
          About
        </NavLink>
        <NavLink
          className="hover:text-blue-600 smooth-transition text-xl"
          to="/contact"
          onClick={() => {
            RemoveMenu()
          }}
          style={({ isActive }) => (isActive ? activeMenuStyles : {})}
        >
          Contact
        </NavLink>
        <Socials />
          <p className='text-lg'>&copy; 2022-2023 by Anish Nayak.</p>
      </nav>
    </header></div>
  )
}

export default SideMenu
