// import { useState } from 'react'

import emailjs from '@emailjs/browser'
import { useEffect, useState } from "react"
const ContactForm = () => {
  const [status, setStatus] = useState('');
  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('anish', 'template_40gu5j9', e.target, 'Xr2jHY1YnFsebyoat')
      .then((result) => {
          console.log(result.text);
          setStatus('SUCCESS');
      }, (error) => {
        console.log('FAILED...', error.text);
      });
  }
  useEffect(() => {
    if(status === 'SUCCESS') {
      setTimeout(() => {
        setStatus('');
      }, 3000);
    }
  }, [status]);

  return (
    <form
    onSubmit={sendEmail}
    className='w-full max-w-lg'
  >
{status && renderAlert()}
<div className="flex flex-wrap -mx-3 mb-6">
        
        <div className="w-full px-3">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
            
          >
            Full Name
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            name="from_name"
            type="text"
          ></input>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs mb-2"
            htmlFor="grid-password"
          >
            E-mail
          </label>
          <input
            className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            id="email"
            name="email"
            type="email"
          ></input>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <label
            className="block uppercase tracking-wide text-gray-700 text-xs  mb-2"
            htmlFor="grid-password"
          >
            Message
          </label>
          <textarea
            className=" no-resize appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 h-48 resize-none"
            id="message"
            name="message"
          ></textarea>
        </div>
      </div>
      <div className="md:flex md:items-center">
        <div className="md:w-1/3">
          <button
            className="shadow bg-blue-600 hover:bg-blue-400 smooth-transition focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            type="submit"
          >
            Send
          </button>
        </div>
        <div className="md:w-2/3"></div>
      </div>
  </form>
  );
}
const renderAlert = () => (
  <div className="px-4 py-3 leading-normal text-blue-700 bg-blue-100 rounded mb-5 text-center">
    <p>Your message was successfully submitted</p>
  </div>
)


export default ContactForm
